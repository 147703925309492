.link-list {
  padding: 20px;
  list-style-type: none;
}

.link-list-item:first-child {
  margin-top: -10px;
}

.link-list-item {
  list-style-type: none;
  text-align: left;
  font-size: 0.9rem;
  margin-top: 10px;
}

a {
  text-decoration: none;
}
a:link {color: inherit;}  
a:visited {color: inherit}  
a:hover {color: inherit}  
a:active {color: inherit}  

.link-list-item-url {
  text-decoration: none;
  margin: 60px;
  display: block;
  color: #1d1d1d;
  background-color: #f1f1f1;
  padding: 80px;
  border-radius: 3px;
  box-shadow: 2px 2px 4px rgba(150, 149, 149, 0.4);
}
